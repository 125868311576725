import React from "react";
import pt from "prop-types";
import formatcoords from "formatcoords";

export const Address = ({ address, lat, lng }) => {
  const fancylatLon = formatcoords(lat, lng).format('DDMMssX');
  
  return (
    <div className="Address">
      <div className="Address__line">
        <p>
          <span className="linebreak-span">{address.line1}</span>{" "}
          <span className="linebreak-span">{address.line2}</span>{" "}
          <span className="linebreak-span">{address.city}</span>
        </p>
      </div>
      <div className="Address__latLon">{fancylatLon}</div>
    </div>
  );
};

Address.propTypes = {
  address: pt.object,
  lat: pt.number,
  ln: pt.number,
};

export default Address;
