import React, { Component } from "react";
import pt from "prop-types";
import classNames from "classnames";

class Button extends Component {
  onClick = () => {
    this.props.onClick();
  };
  render() {
    const { value, label } = this.props;
    const classes = classNames("Button", {
      "Button--inactive": !this.props.isActive,
      "Button--white": this.props.isWhite,
      "Button--large": this.props.isLarge,
      "Button--blackLabel": this.props.isBlackLabel,
    });
    return (
      <button
        className={classes}
        type="text"
        value={value}
        onClick={this.onClick}
      >
        {label}
      </button>
    );
  }
}

Button.propTypes = {
  isActive: pt.bool,
  isWhite: pt.bool,
  isLarge: pt.bool,
  isBlackLabel: pt.bool,
  label: pt.string.isRequired,
  onClick: pt.func.isRequired,
};

Button.defaultProps = {
  isActive: true,
  onClick: () => {},
};

export default Button;
