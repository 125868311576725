const ROUTES = {
  results: "results",
  solutions: "solutions",
  page: "page",
  confirmation: "confirmation",
  confirmSubmission: "confirm-submission",
  unconfirmSubmission: "unconfirm-submission",
  unsubscribe: "unsubscribe",
};

export default ROUTES;
