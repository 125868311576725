import React, { Component } from "react";
import DropdownText from "../../components/Common/DropdownText/DropdownText";
import Share from "../../components/Confirmation/Share/Share";
import ShareUrls from "../../services/ShareUrls";
import strings from "../../strings";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faWhatsapp,
  faTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";

class Confirmation extends Component {
  render() {
    return (
      <div className="Confirmation">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        <div className="Confirmation__hero">
          <span dangerouslySetInnerHTML={{ __html: strings.confirmation.hero}}/>
          <div className="Confirmation__heroSubtitle">
            <span>{strings.confirmation.heroSubtitle}</span>
          </div>
        </div>
        <DropdownText
          title={strings.confirmation.dropdownShareTitle}
          defaultIsOpen
        >
          <div className="Confirmation__shareLinks">
            <Share
              windowWidth={600}
              windowHeight={450}
              url={ShareUrls.mailShareUrl()}
              icon={faEnvelope}
              disableOpensNewWindow
            />
            <Share
              windowWidth={600}
              windowHeight={450}
              url={ShareUrls.twitterShareUrl()}
              icon={faTwitter}
            />
            <Share
              windowWidth={600}
              windowHeight={450}
              url={ShareUrls.facebookShareUrl()}
              icon={faFacebookF}
            />
            <Share url={ShareUrls.whatsappShareUrl()} icon={faWhatsapp} />
            <Share
              windowWidth={600}
              windowHeight={600}
              url={ShareUrls.linkedInShareUrl()}
              icon={faLinkedinIn}
            />
          </div>
        </DropdownText>
        <DropdownText
          containsHtml={true}
          title={strings.confirmation.dropdownLearnMoreTitle}
        >
          {strings.confirmation.dropdownLearnMoreCopy}
        </DropdownText>
        <DropdownText
          containsHtml={true}
          title={strings.confirmation.dropdownGovDoTitle}
        >
          {strings.confirmation.dropdownGovDoCopy}
        </DropdownText>

      </div>
    );
  }
}

export default Confirmation;
