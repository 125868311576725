import React, { Component } from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import * as selectors from "../../../selectors";
import * as actions from "../../../modules/actions";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import AddressList from "../AddressList/AddressList";
import strings from "../../../strings/";
import { Redirect } from "react-router-dom";
import ROUTES from "../../../routes/index";
import { isValidPostcode } from "../../../utils";

const VALIDATION_TIMEOUT = 3000;
class PostcodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null,
      idToRedirectTo: null,
      shouldShowValidationErrors: false,
      postcodeIsValid: false,
    };

    this.validationTimeout = null;
  }
  startTimeout() {
    if (this.validationTimeout) {
      clearTimeout(this.validationTimeout);
    }
    this.validationTimeout = setTimeout(() => {
      this.setState({ shouldShowValidationErrors: true });
    }, VALIDATION_TIMEOUT);
  }
  handleChange = (newValue) => {
    this.startTimeout();
    this.props.searchUpdateCurrent({ newValue });
    if (!isValidPostcode(newValue)) {
      this.setState({ postcodeIsValid: false });
      return;
    } else {
      this.setState({ postcodeIsValid: true });
    }
    this.props.searchGetAddresses({ postcode: newValue });
  };
  handleSubmit = () => {
    const idToRedirectTo = this.state.selectedAddress.id;
    this.clearForm();
    this.setState({ idToRedirectTo });
  };
  handleClear = () => {
    this.clearForm();
  };
  clearForm = () => {
    this.setState({ selectedAddress: null });
    this.props.searchUpdateCurrent({ newValue: "" });
    this.props.searchGetAddresses({ postcode: "" });
  };
  handleAddresChange = (selectedAddress) => {
    this.setState({ selectedAddress });
  };
  render() {
    const { currentSearch, results, isAreaError, isWorking } = this.props;
    const {
      selectedAddress,
      idToRedirectTo,
      shouldShowValidationErrors,
      postcodeIsValid,
    } = this.state;
    return (
      <div className="PostcodeForm">
        {!selectedAddress && (
          <Input
            value={currentSearch}
            onChange={this.handleChange}
            placeholder={strings.home.inputCta}
          />
        )}
        {selectedAddress && (
          <Input
            isLocked={true}
            hasClearButton={true}
            value={selectedAddress.formatted_address}
            onClear={this.handleClear}
          ></Input>
        )}
        {postcodeIsValid && !isWorking && !selectedAddress && (
          <AddressList
            values={results}
            onSubmit={this.handleSubmit}
            onChange={this.handleAddresChange}
          />
        )}
        {isAreaError && postcodeIsValid && !isWorking && (
          <div className="PostcodeForm__areaError">
            {strings.home.areaError.text}
          </div>
        )}
        {shouldShowValidationErrors && !postcodeIsValid && !isWorking && (
          <div className="PostcodeForm__areaError">
            {strings.home.postcodeError.invalid}
          </div>
        )}
        {results.length > 0 && (
          <Button
            isActive={this.state.selectedAddress !== null}
            onClick={this.handleSubmit}
            label="SUBMIT"
          />
        )}
        {idToRedirectTo && (
          <Redirect push to={`/${ROUTES.results}/` + idToRedirectTo} />
        )}
      </div>
    );
  }
}

PostcodeForm.propTypes = {
  currentSearch: pt.string,
  results: pt.array,
  isAreaError: pt.bool,
  searchUpdateCurrent: pt.func,
  searchGetAddresses: pt.func,
};

const mapStateToProps = (state) => {
  return {
    currentSearch: selectors.postcodeSearchCurrentSearch(state),
    results: selectors.postcodeSearchResults(state),
    isAreaError: selectors.postcodeSearchIsAreaError(state),
    isWorking: selectors.postcodeSearchIsWorking(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchUpdateCurrent: (payload) =>
      dispatch(actions.searchUpdateCurrent(payload)),
    searchGetAddresses: (payload) =>
      dispatch(actions.searchGetAddresses(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostcodeForm);
