import React, { Component } from "react";
import pt from "prop-types";
import classNames from "classnames";
import Indicator from "../../../images/indicator.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

class PollutionIndicator extends Component {
  handleClick(level) {
    return () => {
      if (this.props.clickable) {
        this.props.onClick(level);
      }
    };
  }
  render() {
    const { level, simplified, clickable, percentile } = this.props;
    const numbers = [1, 2, 3, 4, 5].map(num => {
      const classes = classNames(
        "PollutionIndicatorNumber",
        { "PollutionIndicatorNumber--simplified": simplified },
        "PollutionIndicatorNumber--" + num,
        { "PollutionIndicatorNumber--active": num === level }
      );
      return (
        <div className={classes} key={num}>
          <div
            onClick={this.handleClick(num)}
            className="PollutionIndicatorNumber__text"
          >
            {num}
          </div>
          <div
            onClick={this.handleClick(num)}
            className="PollutionIndicatorNumber__color"
          >
            {num === level && percentile}
          </div>
          <div className="PollutionIndicatorNumber__indicator">
            {clickable && level > 1 && (
              <div className="PollutionIndicatorNumber__prev">
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  onClick={this.handleClick(level - 1)}
                />
              </div>
            )}

            <img src={Indicator} alt="current pollution level" />
            {clickable && level < 5 && (
              <div className="PollutionIndicatorNumber__next">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  onClick={this.handleClick(level + 1)}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
    return (
      <div className="PollutionIndicator">
        <div className="PollutionIndicator__numbers">{numbers}</div>
      </div>
    );
  }
}

PollutionIndicator.propTypes = {
  level: pt.number,
  clickable: pt.bool,
  simplified: pt.bool,
  onClick: pt.func,
};

export default PollutionIndicator;
