import React, { Component } from "react";
import Home from "../Home/Home";
import Results from "../Results/Results";
import Solutions from "../Solutions/Solutions";
import StaticPage from "../StaticPage/StaticPage";
import Confirmation from "../Confirmation/Confirmation";
import ConfirmSubmission from "../ConfirmSubmission/ConfirmSubmission";
import UnconfirmSubmission from "../UnconfirmSubmission/UnconfirmSubmission";
import Unsubscribe from "../Unsubscribe/Unsubscribe";
import Menu from "../../components/Global/Menu/Menu";
import { Route, Switch } from "react-router-dom";
import Hamburger from "../../components/Global/Hamburger/Hamburger";
import CloseButton from "../../components/Global/CloseButton/CloseButton";
import { withRouter } from "react-router";
import AnalyticsWrapper from "../../utils/AnalyticsWrapper";
import classnames from "classnames";
import AppFooter from "../../components/Global/AppFooter/AppFooter";

const WrappedHome = AnalyticsWrapper(Home);
const WrappedResults = AnalyticsWrapper(Results);
const WrappedSolutions = AnalyticsWrapper(Solutions);
const WrappedStaticPage = AnalyticsWrapper(StaticPage);
const WrappedConfirmation = AnalyticsWrapper(Confirmation);

const APP_PATHS = {
  HOME: "home",
  RESULTS: "results",
  PRIVACY: "page/privacyPolicy",
};

class App extends Component {
  state = {
    showMenu: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({ showMenu: false });
  }

  handleHamburgerClick = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  handleCloseButton() {
    return this.props.history.goBack();
  }

  render() {
    const { showMenu } = this.state;
    const regex = /(\/)(.*)/;
    const currentRouteMatch = this.props.location.pathname.match(regex);
    const currentRoute = currentRouteMatch
      ? currentRouteMatch[2]
      : APP_PATHS.HOME;
    const currentRouteParts = currentRoute.match(/(.*?)\//);
    const currentRouteBase = currentRouteParts ? currentRouteParts[1] : false;

    const classes = classnames(
      "App",
      "App--" +
        (currentRouteBase === APP_PATHS.RESULTS
          ? currentRouteBase
          : currentRoute),
      {
        "App--menu": showMenu,
      }
    );
    const pathName = this.props.location.pathname;

    return (
      <div className={classes}>
        <div className="App__header">
          {currentRoute !== APP_PATHS.PRIVACY ? (
            <Hamburger
              isActive={showMenu}
              onClick={this.handleHamburgerClick}
            />
          ) : (
            <CloseButton onClick={this.handleCloseButton.bind(this)} />
          )}
        </div>
        <div className="App__content">
          <Switch>
            <Route exact path="/" component={WrappedHome} />
            <Route exact path="/results/:id" component={WrappedResults} />
            <Route exact path="/solutions/:id" component={WrappedSolutions} />
            <Route exact path="/page/:pagename" component={WrappedStaticPage} />
            <Route exact path="/confirmation" component={WrappedConfirmation} />
            <Route
              exact
              path="/confirm-submission/:email/:token"
              component={ConfirmSubmission}
            />
            <Route
              exact
              path="/unconfirm-submission/:email/:token"
              component={UnconfirmSubmission}
            />
            <Route
              exact
              path="/unsubscribe/:email/:token"
              component={Unsubscribe}
            />
            <Route component={WrappedHome} />
          </Switch>
          {showMenu && <Menu onClick={this.hideMenu} />}
        </div>
        <AppFooter
          isHome={pathName === "/home" || pathName === "/"}
          isResults={currentRouteBase === APP_PATHS.RESULTS}
        />
      </div>
    );
  }
}
export default withRouter(App);
