import React, { Component } from "react";
import pt from "prop-types";
import staticPages from "../../strings/staticPages";
import { sanitizer } from "../../utils";
import Question from "../../components/Common/Question/Question";

class StaticPage extends Component {
  render() {
    let pageContent = {
      content: "",
      title: "",
    };
    if (staticPages[this.props.match.params.pagename]) {
      pageContent = staticPages[this.props.match.params.pagename];
    }

    return (
      <div className="StaticPage">
        <div className="StaticPage__title">{pageContent.title}</div>
        <div
          className="StaticPage__content"
          dangerouslySetInnerHTML={{ __html: sanitizer(pageContent.content) }}
        ></div>
        {pageContent.questions && (
          <div className="StaticPage__content">
            {pageContent.questions.map((question, index) => (
              <Question key={index} title={question.title}>
                {question.content}
              </Question>
            ))}
          </div>
        )}
      </div>
    );
  }
}

StaticPage.propTypes = {
  match: pt.object,
};

export default StaticPage;
