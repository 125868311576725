import React, { Component } from "react";
import pt from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

class Hamburger extends Component {
  onClick = () => {
    this.props.onClick();
  };
  render() {
    const icon = this.props.isActive ? faTimes : faBars;
    return (
      <div className="Hamburger" onClick={this.onClick}>
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  }
}

Hamburger.propTypes = {
  isActive: pt.bool,
  onClick: pt.func,
};

export default Hamburger;
