import axios from "axios";

const setupAxios = () => {
  if (
    process.env.NODE_ENV !== "production" &&
    process.env.JEST_WORKER_ID === undefined
  ) {
    axios.interceptors.request.use(request => {
      console.log("Starting Request", request);
      return request;
    });

    axios.interceptors.response.use(response => {
      console.log("Response:", response);
      return response;
    });
  }

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  return axios;
};

export default setupAxios();
