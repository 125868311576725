import React from "react";
import strings from "../../../strings";

export class Attribution extends React.Component {
  state = {
    wrongImageIsExpanded: false,
  };

  handleWrongImageClick = () => {
    this.setState({ wrongImageIsExpanded: !this.state.wrongImageIsExpanded })
  }

  render() {
    const { wrongImageIsExpanded } = this.state;
    return (
      <div className="Attribution" onClick={this.handleWrongImageClick}>
        <div className="Attribution__inner">
          <div
            className="Attribution__description"
            dangerouslySetInnerHTML={{ __html: strings.results.attribution.description }}
          />
          <div className="Attribution__wrongImage">
            <span className="Attribution__wrongImage__underline">Wrong image</span> for address?
          </div>
          {wrongImageIsExpanded &&
            <div
              className="Attribution__wrongImageDescription"
              dangerouslySetInnerHTML={{ __html: strings.results.attribution.wrongImageDescription }}
            />
          }
        </div>
      </div>
    );
  }
}

export default Attribution;
