import React, { Component } from "react";
import pt from "prop-types";
import strings from "../../../strings";
import PollutionIndicator from "../../Common/PollutionIndicator/PollutionIndicator";
import graph from "../../../images/rating-graph.webp";

class PollutionDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popUpIsOpened: false,
    };
  }

  onClick = () => {
    const popUpIsOpened = !this.state.popUpIsOpened;
    this.setState({ popUpIsOpened });
  };

  buildPercentile = (percentile) => {
    const lastDigit = percentile % 10;
    const endings = { 1: "st", 2: "nd", 3: "rd", default: "th" };
    const ending = endings[lastDigit] || endings.default;

    return `${percentile + ending}`;
  };

  render() {
    let {
      levelName,
      levelNumber,
      percentile,
      whoLimit,
      whoLimitDescription,
    } = this.props;

    return (
      <div className="PollutionDescription">
        <h3 className="PollutionDescription__heading">
          <span className="linebreak-span">{levelName}</span>
          <span className="linebreak-span">
            {strings.results.descriptionHeadline}
          </span>
        </h3>
        <PollutionIndicator
          simplified
          level={levelNumber}
          percentile={percentile}
        />
        <div className="PollutionDescription__message">
          <p>
            <span
              dangerouslySetInnerHTML={{ __html: strings.results.messagePart1 }}
            ></span>
            {this.buildPercentile(percentile)}
            <span>{strings.results.messagePart2}</span>
          </p>
          <button onClick={this.onClick}>
            <div
              className="PollutionDescription__tooltip"
              role="tooltip"
              title="more about percentiles"
            >
              ?
            </div>
          </button>
        </div>
        {this.state.popUpIsOpened && (
          <div className="PollutionDescription__popup">
            <div className="PollutionDescription__graph">
              <p>{strings.results.graph.intro}</p>
              <div className="PollutionDescription__imgWrap">
                <img src={graph} alt="ratings graphic" />
              </div>
              <div>
                <p>{strings.results.graph.footer1}</p>
                <p> {strings.results.graph.footer2}</p>
              </div>
            </div>
          </div>
        )}
        {whoLimit && (
          <div className="PollutionDescription__text">
            <p>{whoLimit}</p>
            {whoLimitDescription && <p>{whoLimitDescription}</p>}
          </div>
        )}
      </div>
    );
  }
}

PollutionDescription.propTypes = {
  levelNumber: pt.number,
  levelName: pt.string,
  concentration: pt.number,
};

export default PollutionDescription;
