import React, { Component } from "react";
import ReactGA from "react-ga";

function AnalyticsWrapper(WrappedComponent) {
  return class extends Component {
    componentWillMount() {
      ReactGA.pageview(window.location.pathname);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default AnalyticsWrapper;
