import React, { Component } from "react";
import pt from "prop-types";
import classNames from "classnames";
import { scrollParentToChild, KEYSTROKES } from "../../../utils/";

class AddressList extends Component {
  state = {
    currentItem: -1,
  };
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }
  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyPress.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress.bind(this));
  }
  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      this.changeCurrentItem(-1);
    }
    if (this.props.values.length > 0 && prevProps.values.length === 0) {
      this.element.current.scrollIntoView(true);
    }
  }
  handleKeyPress = e => {
    let currentItem = this.state.currentItem;
    const maxItem = this.props.values.length - 1;

    if (e.key === KEYSTROKES.enter) {
      this.props.onSubmit();
    }
    if (e.key === KEYSTROKES.arrowDown && this.props.values.length > 0) {
      e.preventDefault();
      if (currentItem >= maxItem || currentItem < 0) {
        currentItem = 0;
      } else {
        currentItem += 1;
      }
      this.changeCurrentItem(currentItem);
      scrollParentToChild(
        this.element.current,
        document.querySelector(".AddressList__item--current")
      );
    }
    if (e.key === KEYSTROKES.arrowUp && this.props.values.length > 0) {
      e.preventDefault();
      if (currentItem <= 0) {
        currentItem = maxItem;
      } else {
        currentItem -= 1;
      }
      this.changeCurrentItem(currentItem);
      scrollParentToChild(
        this.element.current,
        document.querySelector(".AddressList__item--current")
      );
    }
  };
  handleClick = index => {
    return () => {
      this.changeCurrentItem(index);
    };
  };
  changeCurrentItem = index => {
    this.setState({ currentItem: index });
    const newCurrentItem = this.props.values[index]
      ? this.props.values[index]
      : null;
    this.props.onChange(newCurrentItem);
  };
  render() {
    const resultsList = this.props.values.map((result, index) => {
      const classes = classNames("AddressList__item", {
        "AddressList__item--current": this.state.currentItem === index,
      });
      return (
        <li className={classes} onClick={this.handleClick(index)} key={index}>
          {result.formatted_address}
        </li>
      );
    });
    return (
      <ul ref={this.element} className="AddressList">
        {resultsList}
      </ul>
    );
  }
}

AddressList.propTypes = {
  onChange: pt.func,
  values: pt.array,
};

export default AddressList;
