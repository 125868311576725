import strings from "../strings/";
const pageUrl = process.env.REACT_APP_URL;

const MAIL_SHARE_URL = "mailto:?subject=";
const TWITTER_SHARE_URL = "https://twitter.com/intent/tweet?text=";
const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?quote=";
const WHATSAPP_SHARE_URL = navigator.userAgent.match(/iPhone|Android/i)
  ? "whatsapp://send?text="
  : "https://wa.me/?text=";
const LINKEDIN_SHARE_URL =
  "http://www.linkedin.com/shareArticle?mini=true&url=";

export class ShareUrls {
  /**
   * Get email share url
   *
   * @static
   * @returns {string} url
   */
  static mailShareUrl() {
    const emailBody = strings.sharingEmail.text + " " + pageUrl;
    return (
      MAIL_SHARE_URL +
      encodeURIComponent(strings.sharingEmail.subject) +
      "&body=" +
      encodeURIComponent(emailBody)
    );
  }
  /**
   * Get twitter share url
   *
   * @static
   * @returns {string} url
   */
  static twitterShareUrl() {
    return (
      TWITTER_SHARE_URL +
      encodeURIComponent(strings.sharingTweetText) +
      "&url=" +
      encodeURIComponent(pageUrl)
    );
  }
  /**
   * Get FB share url
   *
   * @static
   * @returns {string} url
   */
  static facebookShareUrl() {
    return (
      FACEBOOK_SHARE_URL +
      encodeURIComponent(strings.sharingFacebookText) +
      "&u=" +
      encodeURIComponent(pageUrl)
    );
  }
  /**
   * Get Whatsapp share url
   *
   * @static
   * @returns {string} url
   */
  static whatsappShareUrl() {
    return WHATSAPP_SHARE_URL + encodeURIComponent(strings.sharingWhatsappText);
  }
  /**
   * Get LinkedIn share url
   *
   * @static
   * @returns {string} url
   */
  static linkedInShareUrl() {
    return LINKEDIN_SHARE_URL + encodeURIComponent(pageUrl);
  }
}

export default ShareUrls;
