import React, { Component } from "react";
import Spinner from "../../Common/Spinner/Spinner";
import strings from "../../../strings";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import classNames from "classnames";
import pt from "prop-types";
import { validationSchema } from "../../../utils";
import ROUTES from "../../../routes/index";

class PetitionForm extends Component {
  handleSubmit = (values, formikBag) => {
    values.addressId = this.props.addressId;
    this.props.onSubmit({ values, formikBag });
  };
  render() {
    return (
      <div className="PetitionForm">
        <Formik
          validationSchema={validationSchema}
          initialValues={{ name: "", email: "" }}
          onSubmit={this.handleSubmit}
          render={({ status, isSubmitting, errors, isValidating, touched }) => (
            <Form className={classNames({ "--isPending": isSubmitting })}>
              <Field
                placeholder={strings.solutions.nameInputPlaceholder}
                className="Input"
                type="name"
                name="name"
              />
              {errors.name && touched.name && (
                <div className="PetitionForm__validationError">
                  {errors.name}
                </div>
              )}
              <Field
                placeholder={strings.solutions.emailInputPlaceholder}
                className="Input"
                type="email"
                name="email"
              />
              {errors.email && touched.email && (
                <div className="PetitionForm__validationError">
                  {errors.email}
                </div>
              )}
              <button
                className="Button Button--white"
                type="submit"
                disabled={isSubmitting || isValidating}
              >
                {strings.solutions.buttonLabel}
              </button>
              {status && status.msg && (
                <div className="PetitionForm__error">{status.msg}</div>
              )}
              {isSubmitting && <Spinner />}
              {status && status.successful && (
                <Redirect push to={`/${ROUTES.confirmation}/`} />
              )}
            </Form>
          )}
        />
      </div>
    );
  }
}

PetitionForm.propTypes = {
  onSubmit: pt.func,
  addressId: pt.string,
};

export default PetitionForm;
