import React from "react";
import pt from "prop-types";

export const StreetView = ({ lat, lng, backgroundMode, ovedrrideUrl }) => {
  let url = ovedrrideUrl ? ovedrrideUrl : "";
  let style = {};
  /*   if (lat !== 0 && lng !== 0 && !ovedrrideUrl) {
    url = GoogleMaps.getStreetViewUrl(lat, lng, width, height);
  } */
  style = {
    backgroundImage: "url(" + url + ")",
  };
  if (!backgroundMode) {
    return <div className="StreetView">{url && <img src={url} alt="" />}</div>;
  } else {
    return (
      <div className="StreetView StreetView--backgroundMode" style={style} />
    );
  }
};

StreetView.propTypes = {
  lat: pt.number,
  lng: pt.number,
  backgroundMode: pt.bool,
};

StreetView.defaultProps = {
  backgroundMode: false,
};

export default StreetView;
