import React, { Component } from "react";
import pt from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

class Share extends Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }
  handleClick = e => {
    if (this.props.disableOpensNewWindow) return;
    const { url, windowWidth, windowHeight } = this.props;
    e.preventDefault();
    window.open(
      url,
      "Share",
      "width=" + windowWidth + ",height=" + windowHeight
    );
  };
  render() {
    const { url, icon, windowWidth, windowHeight } = this.props;
    const classes = classNames("Share", "Share--" + icon.iconName);
    const clickHandler = windowWidth && windowHeight ? this.handleClick : null;
    return (
      <a
        onClick={clickHandler}
        href={url}
        className={classes}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={icon} />
      </a>
    );
  }
}

Share.propTypes = {
  url: pt.string,
  icon: pt.object,
  windowWidth: pt.number,
  windowHeight: pt.number,
  disableOpensNewWindow: pt.bool,
};

export default Share;
