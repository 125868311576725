import React, { Component } from "react";
import pt from "prop-types";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import * as selectors from "../../selectors";
import * as actions from "../../modules/actions";
import PetitionForm from "../../components/Solutions/PetitionForm/PetitionForm";
import Spinner from "../../components/Common/Spinner/Spinner";
import strings from "../../strings";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";

class Solutions extends Component {
  componentDidMount() {
    if (this.props.match.params.id !== this.props.currentId) {
      this.props.cleanResults();
      this.props.pullResults({ id: this.props.match.params.id });
    }
  }

  render() {
    const { signPetition, solutions, pullResultsHasFailed } = this.props;
    const addressId = this.props.match.params.id;
    const hasData = Object.keys(solutions).length > 0;

    return (
      <div className="Solutions">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        {pullResultsHasFailed && <Redirect to="/" />}
        {!hasData && (
          <div className="Results__content">
            <Spinner />
          </div>
        )}
        {hasData && (
          <div className="Solutions__content">
            <div className="Solutions__mp">
              <div className="Solutions__mpTitle" dangerouslySetInnerHTML={{ __html: strings.solutions.title.hero }} />
              <div className="Solutions__mpContent">
                <p dangerouslySetInnerHTML={{ __html: strings.solutions.body }} />
              </div>
            </div>
            <PetitionForm onSubmit={signPetition} addressId={addressId} />
          </div>
        )}
      </div>
    );
  }
}

Solutions.propTypes = {
  currentId: pt.string,
  mpName: pt.string,
  districtName: pt.string,
};

const mapStateToProps = state => {
  return {
    currentId: selectors.resultsCurrentId(state),
    mpName: selectors.resultsMpName(state),
    districtName: selectors.resultsDistrictName(state),
    solutions: selectors.resultsSolutions(state),
    pullResultsHasFailed: selectors.resultsPullResultsHasFailed(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pullResults: payload => dispatch(actions.pullResults(payload)),
    cleanResults: payload => dispatch(actions.cleanResults(payload)),
    signPetition: payload => dispatch(actions.signPetition(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Solutions);
