import React, { Component } from "react";
import pt from "prop-types";
import Spinner from "../../components/Common/Spinner/Spinner";
import { connect } from "react-redux";
import * as selectors from "../../selectors";
import * as actions from "../../modules/actions";
import ResultsIconWhite from "../../images/results-icon-white.svg";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";

class UnconfirmSubmission extends Component {
  componentWillMount() {
    this.props.unconfirmSubmission({
      email: this.props.match.params.email,
      token: this.props.match.params.token,
    });
  }
  render() {
    const { submissionUnconfirmationStatus } = this.props;

    return (
      <div className="UnconfirmSubmission">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        {!submissionUnconfirmationStatus && <Spinner />}
        <div className="UnconfirmSubmission__status">
          {submissionUnconfirmationStatus}
        </div>
        <img src={ResultsIconWhite} alt="" />
      </div>
    );
  }
}

UnconfirmSubmission.propTypes = {
  match: pt.object,
  submissionUnconfirmationStatus: pt.string,
  unconfirmSubmission: pt.func,
};

const mapStateToProps = state => {
  return {
    submissionUnconfirmationStatus: selectors.submissionUnconfirmationStatus(
      state
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unconfirmSubmission: payload =>
      dispatch(actions.unconfirmSubmission(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconfirmSubmission);
