export const postcodeSearch = state => state.postcodeSearch;
export const postcodeSearchCurrentSearch = state =>
  state.postcodeSearch.currentSearch;
export const postcodeSearchResults = state => state.postcodeSearch.results;
export const postcodeSearchIsAreaError = state =>
  state.postcodeSearch.isAreaError;
export const postcodeSearchIsWorking = state => state.postcodeSearch.isWorking;


export const results = state => state.results;
export const resultsAddress = state => state.results.address;
export const resultsAirPollution = state => state.results.airPollution;
export const resultsSolutions = state => state.results.solutions;
export const resultsLat = state => state.results.lat;
export const resultsLng = state => state.results.lng;
export const resultsCurrentId = state => state.results.currentId;
export const resultsMpName = state => state.results.mpName;
export const resultsDistrictName = state => state.results.districtName;
export const resultsStreetViewUrls = state => state.results.streetViewUrls;
export const resultsConcentration = state => state.results.concentration;
export const resultsHealthCosts = state => state.results.healthCosts;
export const resultsLevel = state => state.results.level;
export const resultsLevelDesc = state => state.results.levelDesc;
export const resultsPercentile = state => state.results.percentile;
export const resultsWhoLimit = state => state.results.whoLimit;
export const resultsWhoLimitDescription = state => state.results.whoLimitDescription;
export const resultsPullResultsHasFailed = state => state.results.pullResultsHasFailed;

export const ratings = state => state.ratings;

export const submissionConfirmationStatus = state =>
  state.submissionConfirmationStatus;
export const submissionUnconfirmationStatus = state =>
  state.submissionUnconfirmationStatus;
