import { addTargetBlankToLinks } from "../utils";

const staticPages = {
  testStaticPage: {
    title: "Test Static Page",
    content: `Line one
    Line two`,
  },
  privacyPolicy: {
    title: "",
    content: `<p><b>Privacy Policy</b></p>
    <p>Your personal data and who sees it.<br><br>
    We are committed to protecting your personal data and information. Your personal data will be seen by the addresspollution.org marketing team. We will contact you via email with updates about the campaign. You can unsubscribe at any time.</p>

    <p>How long do we keep your Personal Data?<br><br>
    We will keep your personal data for a maximum period of 3 years. We will not keep it for longer than is necessary for our purposes. You can opt out at any time.</p>
    
    <p><b>Legal Information</b></p>
    
    <p>If you continue to browse and use addresspollution.org, you are agreeing to comply with and be bound by the following terms and conditions of use.  If you don't agree to the terms, please stop using the website immediately.</p>
    <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
    <p>The site may only be accessed and used by a private individual or by a business to seek information about air pollution directly for that individual or business. Access to and use of the website other than for your personal, non-commercial purposes is strictly prohibited.
    You are not permitted to use the website:</p>
    <ul>
    <li>in any unlawful, fraudulent or commercial manner;</li>
    <li>to tamper with, modify, reverse engineer or amend any part of this site;</li>
    <li>with any automated software, process, program, robot, web crawler, spider, data mining, trawling or other "screen scraping" software, process, program or system.</li>
    </ul>
    <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited unless permission has been granted. Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence. Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.</p>
    <p>Air pollution levels are constantly changing. We are using a model from Imperial College London based on the best data currently available. Please also check other sources for real time data or more recent measurements particularly if you intend to use this data to help you make any important decisions.</p>
    `,
  },
  about: {
    title: "About",
    content: `
    <p>addresspollution.org is a free public service from Central Office of Public Interest (COPI). The website provides every UK address with the most accurate air pollution data available, pulled from a national 20m/sq resolution model created by Imperial College London (ICL). To ensure accuracy, COPI sourced and mapped real world pollution levels recorded at more than 19,500 council monitors in every major town and city across the UK. The scientists at ICL were then able to calibrate to these real world levels - resulting in the most detailed and accurate national model ever created.</p>
    <p>The pollution data provided are annual average levels in 2019: the last ‘normal’ year uninterrupted by travel restrictions or national lockdowns. Concentrations of three toxic pollutants are displayed: PM2.5 (fine particles with a diameter of 2.5 microns or less), PM10 (particles with a diameter of 10 microns or less) and NO2 (Nitrogen Dioxide).</p>
    <p>Addresses are also given their respective percentile ranking relative to the pollution levels at every other address in the UK: the most polluted properties falling into the 99th percentile, the least polluted into the 0th percentile. Please see the FAQ for our ranking methodology.</p>
    <p>An easy to understand Air Quality Report then details the likely health impacts of regularly breathing the levels of pollution at that address. addresspollution.org then invites the public to demand action and ask that estate agents and property websites put people before profits by disclosing air pollution levels to renters and buyers at the earliest opportunity. Air pollution, like asbestos, is a Group 1 carcinogen (cause of cancer). Everyone has the right to know what they’re breathing where they live. According to a <a href='https://addresspollution.org/QC_report.pdf' target="_blank">Queen’s Counsel opinion</a>, estate agents and property websites are breaking the law by not disclosing this information. We want them to act lawfully and make this invisible problem impossible to ignore, so that the government will finally be forced to address it.</p>
    <p>This is a <a href="http://www.centralofficeofpublicinterest.com/" target="_blank">Central Office of Public Interest</a> initiative. COPI is a registered non-profit Community Interest Company (CIC) that runs national campaigns to communicate the science and data of our most pressing environmental issues to the public. </p>
    `,
  },
  faq: {
    title: "FAQ",
    questions: [
      {
        title: "WHAT IS AIR POLLUTION AND WHY IS IT A PROBLEM?",
        content: `
        <p>Air pollution is the release of particles and noxious gases into the atmosphere that are considered harmful to human health and the environment.</p>
        <p>The main pollutants of concern are nitrogen dioxide and nitrogen oxide (NOx gases), particulate matter (PM) ground level ozone, carbon monoxide, sulphur dioxide, hydrocarbons and lead. Each have different sources, health effects and chemical behaviours, making the task of understanding, monitoring and controlling air pollution as a whole very complex.</p>
        <p>Air pollution is now the biggest threat to public health in the UK. Long term health effects include asthma, lung cancer, lung disease, heart disease, diabetes, stroke, and pulmonary disease. There is also a growing body of evidence that maternal exposure to air pollution is associated with adverse birth outcomes, low birth weight, pre-term birth and small gestational age births. Emerging evidence also suggests air pollution may affect neurological development in children.</p>
        <p>Most types of air pollution are invisible, which makes it easy, but dangerous to ignore.</p>
        `,
      },
      {
        title: "WHAT CAUSES AIR POLLUTION?",
        content: `
        <p>Air pollution is largely the result of the combustion of fossil fuels like coal, oil, petrol or diesel that are also warming the planet’s atmosphere.</p>
        <p>Road traffic is the biggest contributing factor to urban air pollution in the U.K. Cleaner alternatives exist in the form of active travel & electric vehicles. They must be embraced sooner.</p>
        <p>Other sources of air pollution include gas hobs & boilers, domestic fires & wood burners, industry, shipping, farming and aviation.</p>
        `,
      },
      {
        title:
          "WHICH TYPES OF POLLUTION DOES ADDRESSPOLLUTION.ORG PROVIDE DATA FOR?",
        content: `
        <p>PM 2.5, PM10 and N02</p>
        <p>Particulate matter (PM)</p>
        <p>PM is a common proxy indicator for air pollution. It affects more people than any other pollutant. The major components of PM are sulfate, nitrates, ammonia, sodium chloride, black carbon, mineral dust and water. It consists of a complex mixture of solid and liquid particles of organic and inorganic substances suspended in the air. While particles with a diameter of 10 microns or less, (≤ PM10) can penetrate and lodge deep inside the lungs, the even more health-damaging particles are those with a diameter of 2.5 microns or less, (≤ PM2.5). PM2.5  can penetrate the lung barrier and enter the blood system. Chronic exposure to particles contributes to the risk of developing cardiovascular and respiratory diseases, as well as of lung cancer.</p>
        <p>Nitrogen dioxide (NO2)</p>
        <p>NO2 is also widely known to be harmful to human health. The main source of NO2 is the combustion of fossil fuels. In the UK, this primarily comes from road transport. Exposures to elevated concentrations of NO2 may contribute to the development of asthma and potentially increase susceptibility to respiratory infections.</p>
        `,
      },
      {
        title: "WHERE IS THE AIR POLLUTION DATA FROM? ",
        content: `
        <p>Imperial College London’s Environmental Research Group (ERG), who are the UK’s leading authority on air pollution. With their sophisticated modelling techniques and network of state of the art air pollution sensors, combined with the monitor data sourced and mapped by COPI from every UK council, ICL have provided us with a national air pollution model accurate to 20m sq.</p>
        <p>addresspollution.org is using the latest available annualised data from 2019. When newer data becomes available, the site will be updated.</p>
        `,
      },
      {
        title: "WHY DOES ADDRESSPOLLUTION.ORG SHOW ANNUAL AVERAGE LEVELS?",
        content: `<p>Levels of air pollution change minute by minute and hour by hour due to factors like traffic levels, weather conditions and air pressure. Not only do annual averages change very little between different years but they also give a clearer representation of the level of pollution for an address, where it is likely that people will be living for a significant length of time.</p>`,
      },
      {
        title: "HOW DOES THE RANKING SYSTEM WORK?",
        content: `
        <p>addresspollution.org provides annual average levels of three pollutants at any address. Addresses are put into their percentile ranking according to these three pollution levels relative to the levels at every other address in the UK. </p>
        <p>Addresses are given a colour coded ranking depending on their pollution levels and percentile ranking:</p>
        <p>LOW (white), this address is within the 0th-19th percentile.</p>
        <p>MEDIUM (yellow), this address is within the 20th-39th percentile.</p>
        <p>SIGNIFICANT (orange), this address is within the 40th-59th percentile.</p>
        <p>HIGH (red), this address is within the 60th-79th percentile.</p>
        <p>VERY HIGH (purple), this address is within the 80th-99th percentile.</p>
        <p>We calculated the percentile rankings by firstly standardising the data of each of the three pollutants at every address (subtracting the average level from the level at each address, then dividing the result by the standard deviation). This allowed us to put every address into its relevant national percentile group based on the standardised data totals.</p>
        <p>The ranking system was developed and designed as an accurate, easy to understand system similar to the ‘Energy Efficiency Rating’ given to every address in the UK.</p>
        `,
      },
      {
        title: "WHY IS MY RANKING DIFFERENT TO WHAT I EXPECTED?",
        content: `
        <p>There could be many contributing factors.</p>
        <p>Higher than expected ratings might be due to busy roads or junctions nearby. Built up areas can retain air pollution build up for longer.
        Lower than expected readings might be due to proximity to parks, wide open spaces or other topographical factors.</p>
        <p>Please remember - the levels provided are annual averages.</p>
        <p>Pollution levels peak and trough at many different times of the day and night, differ between the months and seasons throughout the year, depending on a huge array of factors. </p>
        `,
      },
      {
        title: "IS AIR POLLUTION JUST A LONDON PROBLEM?",
        content: `
        <p>No. </p>
        <p>In October 2020, the government published their latest figures on air pollution data for across the country. The data reveals 75% of reporting zones still have illegal levels of air pollution. </p>
        <p>This demonstrates how the UK government has made almost no progress in meeting legal obligations that should have been met in 2010.</p>
        `,
      },
      {
        title: "HOW CAN I HELP REDUCE AIR POLLUTION AND MY EXPOSURE TO IT?",
        content: `
          <p>
              <u>1 Switch driving a car to walking, cycling or using public transport</u>
          </p>
          <p>
              Walk, cycle, bus, train ... However you like to travel, leave your car at
              home and take to the streets. Car drivers can be exposed to twice as much
              air pollution as pedestrians and nine times more than a cyclist. So as well
              as cutting down the amount of pollution you make, you're reducing your
              exposure to air pollution and getting some exercise too.
          </p>
          <p>
              <u>2 Use your car less</u>
          </p>
          <p>
              You can cut down on car journeys by car-sharing, joining a car-club or
              working from home. You can also swap face-to-face meetings for tele- or
              video- conferencing.
          </p>
          <p>
              <u>3 Drive an electric vehicle</u>
          </p>
          <p>
              If you have to drive, Electric vehicles (EVs) are better than their petrol
              and diesel counterparts - and their costs over a lifetime are cheaper than
              you might think. So when you upgrade your car, consider buying an electric
              vehicle.
          </p>
          <p>
              <u>4 Discover the side streets</u>
          </p>
          <p>
              Using quieter streets when you’re on a bike or on foot can lower your
              exposure to air pollution by 20%.
          </p>
          <p>
              <u>5 Avoid strenuous activity when pollution is high</u>
          </p>
          <p>
              There are about 10 to 20 high pollution days a year when it’s better to
              avoid working out too hard if you have a heart or lung condition. But on
              balance, for most people, most of the time, it is healthier to exercise
              than sit it out.
          </p>
          <p>
              <u>6 Switch your engine off when stationary</u>
          </p>
          <p>
              By turning off your car engine whenever you’re not moving – and it’s safe
              to do so – you’ll help to make the air cleaner for you, other drivers and
              pedestrians.
          </p>
          <p>
              <u>7 Choose Click and Collect</u>
          </p>
          <p>
              Many city workplaces report that half of all deliveries are personal
              parcels for staff. That’s a whole lot of extra vans clogging up the city.
              Instead, choose to have your parcels delivered to a Click and Collect
              location near your home.
          </p>
          <p>
              <u>8 Regularly service your car</u>
          </p>
          <p>
              Remember to service your car regularly to make sure it runs as efficiently
              and cleanly as possible.
          </p>
          <p>
              <u>9 Keep your car tyres inflated</u>
          </p>
          <p>
              Inflating your tyres properly means your car will be more efficient and use
              less fuel.
          </p>
          <p>
              <u>10 Inspire others</u>
          </p>
          <p>
              Encourage others to take the actions above to improve the air we breathe.
          </p>
        `,
      },
      {
        title: "WHO IS BEHIND ADDRESSPOLLUTION.ORG?",
        content: `<p><a href="http://www.centralofficeofpublicinterest.com/" target="_blank">Central Office of Public Interest</a>. COPI is a registered Community Interest Company (CIC) that runs campaigns for people and the planet, not profits.</p>
        <p>Using scientific data from academic sources, COPI runs awareness campaigns to deepen public understanding on complex issues relating to public health, welfare and the environment. It's an ever growing alliance of creatives, scientists, film makers, musicians and designers who all feel this invisible toxic problem that goes hand in hand with climate change can no longer be ignored.</p>
        <p>The initial funds for the billboards and other unavoidable campaign costs were raised via a public <a href="https://www.crowdfunder.co.uk/peoplevsairpollution" target="_blank">Crowdfunder</a>. The latest data and nationwide rollout have been carried out with funding from the European Climate Foundation.</p>
      `,
      },
      {
        title: "WHAT IS THE PURPOSE OF ADDRESSPOLLUTION.ORG? ",
        content: `
        <p>Air pollution is an invisible killer, yet public awareness of toxicity, its health effects and how to reduce exposure remain woefully low. It’s killing thousands every year, and harming the long term health of hundreds of thousands more.</p>

        <p>But unlike asbestos, schools, crime rates, Japanese knotweed, broadband speeds, transport links and local amenities, people tend to not consider air pollution levels when they’re moving house. Air pollution, like asbestos, is a group 1 carcinogen. Air Quality Rankings must be legally disclosed to prospective renters and buyers alike at the earliest opportunity. This is backed up by a Queen's Counsel opinion, available <a href="/QC_report.pdf" target="_blank">here</a>.</p>

        <p>We will not stop until it is mandatory for every property portal, estate agent or seller to list the air pollution rating of every house or flat they manage. By making this invisible problem impossible to ignore, the government will finally be forced to address it.</p>
        `,
      },
      {
        title: "WHY IS IT SHOWING THE WRONG IMAGE FOR MY ADDRESS?",
        content: `
        <p>The image is supplied by Google Street View. In some instances, it may not show the property searched for. This has no bearing on the accuracy of the rating. The air pollution concentration is supplied by Imperial College London and is pulled from exact latitude and longitude coordinates of the address itself. </p>
        `,
      },
    ],
  },
  contact: {
    title: "Contact",
    content: `<p><a href="mailto:info@addresspollution.org">info@addresspollution.org</a></p>`,
  },
};

export default addTargetBlankToLinks(staticPages);
