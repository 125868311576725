import React, { Component } from "react";
import pt from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { sanitizer } from "../../../utils/";

class DropdownText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      extended: !!props.defaultIsOpen,
    }
  }
  onClick = () => {
    const extended = !this.state.extended;
    this.setState({ extended });
  };
  render() {
    const { children, title, titleContainsHtml, icon, containsHtml, partialHtml } = this.props;
    const classes = classNames("DropdownText", {
      "DropdownText--active": this.state.extended,
      "DropdownText--icon": icon,
    });
    return (
      <div className={classes}>
        <div className="DropdownText__title" onClick={this.onClick}>
          {icon && (
            <div className="DropdownText__icon">
              <img src={icon} alt="" />
            </div>
          )}
          {!titleContainsHtml ? (
            <div className="DropdownText__titleText">{title}</div>
          ):
          (
            <div
              className="DropdownText__titleText"
              dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
          )}
          
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        {partialHtml ? (
          <>
            <div
              className="DropdownText__content DropdownText__content--withHtml"
              dangerouslySetInnerHTML={{ __html: sanitizer(partialHtml) }}
            ></div>
            <dev className="DropdownText__content">{children}</dev>
          </>
        ) : containsHtml ? (
          <div
            className="DropdownText__content DropdownText__content--withHtml"
            dangerouslySetInnerHTML={{ __html: sanitizer(children) }}
          ></div>
        ) : (
          <div className="DropdownText__content">{children}</div> 
        )}
      </div>
    );
  }
}

DropdownText.propTypes = {
  title: pt.string,
  titleContainsHtml: pt.bool,
  icon: pt.string,
  containsHtml: pt.bool,
  defaultIsOpen: pt.bool,
};

export default DropdownText;
