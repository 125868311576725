import axios from "./Axios";
import { TYPES } from "../utils/";

export class ApiService {
  /**
   * Get list of addresses for postcode
   *
   * @static
   * @param {string} postcode
   * @returns {object} response
   */
  static getAddressesByPostCode(postcode) {
    const params = {};
    if (typeof postcode !== TYPES.string) {
      return {};
    }
    params.postcode = postcode;
    return axios.get("addresses", {
      params,
    });
  }
  /**
   * Get data for given address by id
   *
   * @static
   * @param {number} id
   * @returns {object} response
   */
  static getAddress(id) {
    return axios.get("addresses/" + id);
  }
  /**
   * Get all air pollution ratings
   *
   * @static
   * @returns {object} response
   */
  static getRatings(id) {
    return axios.get("air-pollution-ratings");
  }

  /**
   * Sign petition.
   *
   * @param {string} name
   * @param {string} email
   * @param {string} addressId
   * @static
   * @returns {object} response
   */
  static signPetition(name, email, addressId) {
    let form_data = new FormData();
    form_data.append("name", name);
    form_data.append("email", email);
    form_data.append("addressId", addressId);
    return axios.post("petitions/sign", form_data);
  }

  /**
   * Unsign petition.
   *
   * @param {string} name
   * @param {string} email
   * @static
   * @returns {object} response
   */
  static unsignPetition(name, email) {
    let form_data = new FormData();
    form_data.append("name", name);
    form_data.append("email", email);
    return axios.post("petitions/unsign", form_data);
  }

  /**
   * Confirm petition signing mail
   *
   * @param {string} email
   * @param {string} token
   * @static
   * @returns {object} response
   */
  static confirmSubmission(email, token) {
    return axios.get(`petitions/confirmation/${email}/${token}`);
  }

  /**
   * Confirm petition unsubscribtion mail
   *
   * @param {string} email
   * @param {string} token
   * @static
   * @returns {object} response
   */
  static unconfirmSubmission(email, token) {
    return axios.get(`petitions/remove/${email}/${token}`);
  }
}

export default ApiService;
