import React, { Component } from "react";
import pt from "prop-types";
import Spinner from "../../components/Common/Spinner/Spinner";
import { connect } from "react-redux";
import * as selectors from "../../selectors";
import * as actions from "../../modules/actions";
import { Link } from "react-router-dom";
import strings from "../../strings/";
import ROUTES from "../index";
import ResultsIcon from "../../images/results-icon.svg";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";

class ConfirmSubmission extends Component {
  componentWillMount() {
    this.props.confirmSubmission({
      email: this.props.match.params.email,
      token: this.props.match.params.token,
    });
  }
  render() {
    const { submissionConfirmationStatus } = this.props;
    const { email, token } = this.props.match.params;
    const linkTarget = `/${ROUTES.unconfirmSubmission}/${email}/${token}`;
    return (
      <div className="ConfirmSubmission">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        {!submissionConfirmationStatus && <Spinner />}
        <div className="ConfirmSubmission__status">
          {submissionConfirmationStatus}
        </div>
        <img className="ConfirmSubmission__logo" src={ResultsIcon} alt="" />
        {submissionConfirmationStatus ===
          strings.submissionConfirmation.confirmed && (
          <div className="ConfirmSubmission__unsubscribe">
            <Link to={linkTarget}>
              {strings.submissionConfirmation.unsubscribe}
            </Link>
          </div>
        )}
      </div>
    );
  }
}

ConfirmSubmission.propTypes = {
  match: pt.object,
  submissionConfirmationStatus: pt.string,
  confirmSubmission: pt.func,
};

const mapStateToProps = state => {
  return {
    submissionConfirmationStatus: selectors.submissionConfirmationStatus(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmSubmission: payload => dispatch(actions.confirmSubmission(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmSubmission);
