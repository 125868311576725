import React, { Component } from "react";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";
import PostcodeForm from "../../components/Home/PostcodeForm/PostcodeForm";
import LandingImage from "../../images/landing-image.gif";
import strings from "../../strings/";

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        <div className="Home__headline">{strings.home.headline}</div>
        <div className="Home__image">
          <img alt="" src={LandingImage} />
        </div>
        <div className="Home__cta">{strings.home.cta}</div>
        <PostcodeForm />
        <div
          className="Home__relaunch"
          dangerouslySetInnerHTML={{ __html: strings.home.relaunch }}
        ></div>
      </div>
    );
  }
}

Home.propTypes = {};

export default Home;
