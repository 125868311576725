import React, { Component } from "react";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import strings from "../../../strings";

class SocialIcons extends Component {
  render() {
    return (
      <div className="social-icons__wrap" title="social links" role="group">
        <a
          className="social-icon"
          href={strings.footerSocialLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          className="social-icon"
          href={strings.footerSocialLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          className="social-icon"
          href={strings.footerSocialLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </div>
    );
  }
}

export default SocialIcons;
