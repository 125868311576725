import React, { Component } from "react";
import ReactDOM from "react-dom";
import pt from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import lowerCase from "lower-case";
import { scrollWindowToElement, sanitizer } from "../../../utils/";

class Question extends Component {
  state = {
    extended: false,
  };
  onClick = () => {
    const extended = !this.state.extended;
    this.setState({ extended });
  };
  componentDidMount() {
    const extended = window.location.hash === "#" + this.getAnchorId();
    this.setState({ extended });
    if (extended) {
      scrollWindowToElement(ReactDOM.findDOMNode(this));
    }
  }
  getAnchorId() {
    const { title } = this.props;
    let id = lowerCase(title);
    id = id.replace(/ /g, "").replace(/\?/g, "");
    return encodeURIComponent(id);
  }
  render() {
    const { children, title } = this.props;
    const classes = classNames("Question", {
      "Question--active": this.state.extended,
    });
    return (
      <div className={classes} id={this.getAnchorId()}>
        <div className="Question__title" onClick={this.onClick}>
          <div className="Question__titleText">{title}</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <div
          className="Question__content"
          dangerouslySetInnerHTML={{ __html: sanitizer(children) }}
        ></div>
      </div>
    );
  }
}

Question.propTypes = {
  title: pt.string,
};

export default Question;
