import { configureStore } from "redux-starter-kit";
import reducer from "./modules/reducer";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { homeWatcher } from "./sagas/sagas";

export class Store {
  static configure() {
    const middleware = [];

    if (process.env.NODE_ENV !== "production") {
      middleware.push(logger);
    }

    const sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);

    const store = configureStore({
      reducer,
      middleware,
      devTools: process.env.NODE_ENV !== "production",
    });

    sagaMiddleware.run(homeWatcher);

    return store;
  }
}

export default Store;
