import { createAction } from "redux-starter-kit";

/* Search address */
export const searchGetAddresses = createAction("SEARCH_GET_ADDRESSES");
export const SEARCH_GET_ADDRESSES = searchGetAddresses.toString();
export const searchSetList = createAction("SEARCH_SET_LIST");
export const SEARCH_SET_LIST = searchSetList.toString();
export const searchSetError = createAction("SEARCH_SET_ERROR");
export const SEARCH_SET_ERROR = searchSetError.toString();
export const searchUpdateCurrent = createAction("SEARCH_UPDATE_CURRENT");
export const SEARCH_UPDATE_CURRENT = searchUpdateCurrent.toString();

export const cleanResults = createAction("CLEAN_RESULTS");
export const CLEAN_RESULTS = cleanResults.toString();
export const pullResults = createAction("PULL_RESULTS");
export const PULL_RESULTS = pullResults.toString();
export const pullResultsFail = createAction("PULL_RESULTS_FAIL");
export const PULL_RESULTS_FAIL = pullResultsFail.toString();
export const fillResults = createAction("FILL_RESULTS");
export const FILL_RESULTS = fillResults.toString();

export const pullRatings = createAction("PULL_RATINGS");
export const PULL_RATINGS = pullRatings.toString();
export const fillRatings = createAction("FILL_RATINGS");
export const FILL_RATINGS = fillRatings.toString();

export const signPetition = payload => {
  return {
    type: "SIGN_PETITION",
    payload: payload.values,
    meta: payload.formikBag,
  };
};
export const SIGN_PETITION = "SIGN_PETITION";

export const confirmSubmission = createAction("CONFIRM_SUBMISSION");
export const CONFIRM_SUBMISSION = confirmSubmission.toString();
export const setSubmissionConfirmationStatus = createAction(
  "SET_SUBMISSION_CONFIRMATION_STATUS"
);
export const SET_SUBMISSION_CONFIRMATION_STATUS = setSubmissionConfirmationStatus.toString();

export const unconfirmSubmission = createAction("UNCONFIRM_SUBMISSION");
export const UNCONFIRM_SUBMISSION = unconfirmSubmission.toString();
export const setSubmissionUnconfirmationStatus = createAction(
  "SET_SUBMISSION_UNCONFIRMATION_STATUS"
);
export const SET_SUBMISSION_UNCONFIRMATION_STATUS = setSubmissionUnconfirmationStatus.toString();
