import { addTargetBlankToLinks } from "../utils";

const strings = {
  home: {
    headline: "addresspollution.org",
    cta: "Get a free Air Quality report for your address",
    inputCta: "Enter postcode here",
    areaError: {
      text: "Postcode not found",
    },
    postcodeError: {
      invalid: "The postcode you entered is invalid",
    },
    prelaunch: {
      message: "Relaunching with new data & rating system soon",
      social: "Follow us on social media for updates",
    },
    relaunch:
      "PROVIDING THE PUBLIC WITH THE<br>MOST ACCURATE AIR POLLUTION DATA AVAILABLE",
  },
  results: {
    headline: "Air Quality Report",
    descriptionHeadline: "Air Pollution",
    descriptionExceeding: {
      over40: "EXCEEDS ANNUAL<br>LEGAL LIMIT",
      over60: "GREATLY EXCEEDS<br>ANNUAL LEGAL LIMIT",
      over80: "OVER DOUBLE<br>ANNUAL LEGAL LIMIT",
    },
    descriptionText: "NITROGEN DIOXIDE ANNUAL AVERAGE:",
    descriptionUnit: " MICROGRAMS/m3",
    healthCosts: "LEVELS AND HEALTH EFFECTS",
    healthCostsDropdown: "LEVELS &amp; HEALTH EFFECTS",
    financialCosts: "Financial Costs",
    solutionsCta: "DEMAND ACTION",
    attribution: {
      description:
        "Air Quality data supplied by Imperial College. <br>Image from Google.",
      wrongImageDescription:
        "The image is supplied by Google Street View. In some instances, it may not show the property searched for. This has no bearing on the accuracy of the rating. The air pollution concentration is pulled from exact latitude and longitude coordinates of the address itself.",
    },
    messagePart1: "This address is in <br /> the ",
    messagePart2: " national percentile",
    graph: {
      intro:
        "Every address in the UK has been ranked according to its air pollution levels, relative to other addresses in the country. This number, the percentile ranking, makes it easy to compare addresses.",
      footer1: "Percentile groups (0th-99th)",
      footer2: "Each percentile represents 1% of addresses in the UK",
    },
  },
  solutions: {
    title: {
      hero:
        "<span class='linebreak-span'>AIR POLLUTION IS A KILLER, </span> <span class='linebreak-span'>JUST LIKE ASBESTOS</span>",
    },
    body: `
    <p>We’re calling on estate agents and websites like Rightmove and Zoopla to act lawfully and disclose air pollution ratings on all property listings.</p>
    <p>Air pollution is the biggest environmental risk to public health and, like asbestos, is a cause of cancer.</p>
    <p>People need to know. Everyone has a right to know what they’re breathing.</p>
    <p>Join us and sign our petition below.</p>
    `,
    nameInputPlaceholder: "Name",
    emailInputPlaceholder: "Email",
    buttonLabel: "Submit",
    serverError: "Server error, please try again later",
  },
  footer: {
    version: "VERSION 3.0 - UPDATED APRIL 2022",
    message1: `Brought to you by the  <a href="http://www.centralofficeofpublicinterest.com/">Central Office of Public Interest</a>`,
    message2: "Data supplied by King’s College London",
    privacyPolicyLink: "Terms of Service",
  },
  footerSocialLinks: {
    twitter: "https://twitter.com/AddresPollution",
    instagram: "https://www.instagram.com/addresspollution",
    facebook: "https://www.facebook.com/AddressPollution.org",
  },
  confirmation: {
    hero:
      "<span class='linebreak-span linebreak-span--mobile-only'>YOUR VOICE</span> <span class='linebreak-span linebreak-span--mobile-only'>WILL BE HEARD</span>",
    heroSubtitle: "#addresspollution",
    dropdownShareTitle: "HELP US SPREAD THE WORD",
    dropdownShareContent: `<p>What are you breathing? Get a free Air Quality report to your front door. See if it exceeds World Health Organization limits for clean air: <a href="http://addresspollution.org/" title="http://addresspollution.org/">addresspollution.org</a></p>`,
    confirmation: "Mortgage health calculator",
    dropdownLearnMoreTitle: "WHAT ELSE CAN I DO?",
    dropdownLearnMoreCopy: `
    <p><u>1 Switch driving a car to walking, cycling or using public transport</u><br>
    Walk, cycle, bus, train ... However you like to travel, leave your car at home and take to the streets. Car drivers can be exposed to twice as much air pollution as pedestrians and nine times more than a cyclist. So as well as cutting down the amount of pollution you make, you're reducing your exposure to air pollution and getting some exercise too.</p>

    <p><u>2 Use your car less</u><br>
    You can cut down on car journeys by car-sharing, joining a car-club or working from home. You can also swap face-to-face meetings for tele- or video- conferencing.</p>

    <p><u>3 Drive an electric vehicle</u><br>
    Electric vehicles (EVs) are the future - and their costs over a lifetime are cheaper than you might think. So when you upgrade your car, consider buying an electric vehicle.</p>

    <p><u>4 Discover the side streets</u><br>
    Using quieter streets when you’re on a bike or on foot can lower your exposure to air pollution by 20%.</p>

    <p><u>5 Avoid strenuous activity when pollution is high</u><br>
    There are about 10 to 20 high pollution days a year when it’s better to avoid working out too hard if you have a heart or lung condition. But on balance, for most people, most of the time, it is healthier to exercise than sit it out.</p>

    <p><u>6 Switch your engine off when stationary</u><br>
    By turning off your car engine whenever you’re not moving – and it’s safe to do so – you’ll help to make the air cleaner for you, other drivers and pedestrians.</p>

    <p><u>7 Choose Click and Collect</u><br>
    Many city workplaces report that half of all deliveries are personal parcels for staff. That’s a whole lot of extra vans clogging up the city. Instead, choose to have your parcels delivered to a Click and Collect location near your home.</p>

    <p><u>8 Regularly service your car</u><br>
    Remember to service your car regularly to make sure it runs as efficiently and cleanly as possible.</p>

    <p><u>9 Keep your car tyres inflated</u><br>
    Inflating your tyres properly means your car will be more efficient and use less fuel.</p>

    <p><u>10 Inspire others</u><br>
    Encourage others to take the actions above to improve the air we breathe.</p>
    `,
    dropdownGovDoTitle: "WHAT SHOULD THE GOVERNMENT AND COUNCILS DO?",
    dropdownGovDoCopy: `
    <ol>
      <li>
        Promote and encourage active travel
        <ul>
          <li>Raise public awareness of the health benefits of walking and cycling.</li>
          <li>Offer bicycle subsidies to encourage higher rates of ownership.</li>
          <li>Invest in walking and cycling infrastructure: pavements and protected cycle lanes make cycling safer.</li>
          <li>Install cycle hangers on every street, to allow those with limited space to store a bike safely.</li>
        </ul>
      </li>
      <li>
        Reduce vehicle traffic
        <ul>
          <li>Pedestrianise major shopping centre streets during daylight hours. Research shows local businesses benefit as footfall increases.</li>
          <li>Penalise polluting vehicles to force alternative methods of travel to be considered.</li>
          <li>Data shows road space and car usage are directly correlated. Low Traffic Neighbourhoods and School Streets close down the rat runs, making streets safer and the air cleaner.</li>
          <li>Create 20mph speed limits. Slower speeds mean less pollution.</li>
        </ul>
      </li>
      <li>
        Take the issue of air pollution seriously
        <ul>
          <li>Enshrine W.H.O. air pollution limits into UK law. </li>
          <li>Ban all petrol and diesel cars from city centres as quickly as possible.</li>
          <li>Introduce a nationwide ban on engine idling. This simple step could help reduce urban pollution by up to 20% overnight.</li>
          <li>Fund buy-backs of older, more polluting vehicles.</li>
          <li>Increase subsidies on electric vehicles, so more people who have to drive can afford them.</li>
          <li>Increase the number of electric car charging points and retrofit existing infrastructure like lampposts.</li>
          <li>Bring the ban on the sale of new petrol and diesel cars forward to 2025.</li>
          <li>Increase taxes on SUVs and other high polluting vehicles.</li>
          <li>Introduce better and cleaner public transport infrastructure between and in all UK cities and towns.</li>
          <li>Replace dirty diesel buses with newer, electric models and incentivise bus travel with cheaper fares.</li>
          <li>Electrify the rail network, replace the old diesel fleet and incentivise rail travel with cheaper fares.</li>
          <li>Force delivery companies to use only electric vehicles.</li>
          <li>Protect parks, expand green spaces and plant trees in towns and cities.</li>
          <li>Install green walls beside busy roads, schools and hospitals, and require buildings to install green roofs. </li>
          <li>Ban solid fuel burners such as wood-burning stoves and coal fires in built up areas.</li>
          <li>Port Cities: require all ports to install ‘Cold Ironing’ - plugging ships into local power supplies rather than running on engines.</li>
        </ul>
      </li>
    `,
  },
  sharingTweetText:
    "What are you breathing? Get a free Air Quality report for your address. See if it exceeds World Health Organization limits for clean air: #addresspollution ",
  sharingEmail: {
    subject: "What are you breathing?",
    text:
      "Get a free Air Quality report for your address. See if it exceeds World Health Organization limits for clean air.",
  },
  sharingWhatsappText:
    "What are you breathing? Get a free Air Quality report for your address. See if it exceeds World Health Organization limits for clean air: addresspollution.org",
  sharingFacebookText:
    "What are you breathing? Get a free Air Quality report for your address. See if it exceeds World Health Organization limits for clean air: https://addresspollution.org #addresspollution",
  menu: {
    home: "Home",
  },
  unsubscribe: {
    link: "Unsubscribe",
  },
  submissionConfirmation: {
    confirmed: "Thank you. Email confirmed.",
    error: "Incorrect confirmation token",
    serverError: "Server error, please try again later",
    unsubscribe: "Unsubscribe",
  },
  submissionUnconfirmation: {
    confirmed: "Unsubscribed.",
    error: "Incorrect unconfirmation token",
    serverError: "Server error, please try again later",
  },
  googleTermsOfService: `<a href="https://www.google.com/intl/en_uk/help/terms_maps/">Google Terms of Service</a>`,
  googlePrivacyPolicy: `<a href="https://policies.google.com/privacy?hl=en-US">Google Privacy Policy</a>`,
};

export default addTargetBlankToLinks(strings);
