import React, { Component } from "react";
import pt from "prop-types";
import staticPages from "../../../strings/staticPages";
import strings from "../../../strings";
import { Link } from "react-router-dom";
import DropdownText from "../../Common/DropdownText/DropdownText";
import Question from "../../Common/Question/Question";

class Menu extends Component {
  onClick = () => {
    this.props.onClick();
  };
  render() {
    return (
      <div className="Menu">
        <div className="Menu__inner">
          <Link onClick={this.onClick} to="/">
            {strings.menu.home}
          </Link>
          <DropdownText containsHtml={true} title={staticPages.about.title}>
            {staticPages.about.content}
          </DropdownText>
          <DropdownText title={staticPages.faq.title}>
            {staticPages.faq.questions.map((question, index) => (
              <Question key={index} title={question.title}>
                {question.content}
              </Question>
            ))}
          </DropdownText>
          <DropdownText containsHtml={true} title={staticPages.contact.title}>
            {staticPages.contact.content}
          </DropdownText>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  onClick: pt.func,
};

export default Menu;
