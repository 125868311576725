import React, { Component } from "react";
import pt from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class Input extends Component {
  handleChange = e => {
    const newValue = e.target.value;
    this.props.onChange(newValue);
  };
  handleClear = e => {
    this.props.onClear();
  };
  render() {
    const { value, placeholder, isLocked, hasClearButton } = this.props;
    return (
      <div className="InputWrapper">
        {hasClearButton && (
          <div onClick={this.handleClear} className="InputWrapper__clearButton">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        <input
          className={classNames("Input", { "Input--locked": isLocked })}
          disabled={isLocked}
          type="text"
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

Input.propTypes = {
  isLocked: pt.bool,
  hasClearButton: pt.bool,
  value: pt.string.isRequired,
  onChange: pt.func,
  onClear: pt.func,
  placeholder: pt.string,
};

Input.defaultProps = {
  isLocked: false,
  hasClearButton: false,
};

export default Input;
