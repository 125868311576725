import { put, all, takeLatest, call } from "redux-saga/effects";
import ApiService from "../services/ApiService";
import * as actions from "../modules/actions";
import { handleError, HTTP_STATUSES } from "../utils/";
import strings from "../strings/";

export function* homeWatcher() {
  yield all([takeLatest(actions.SEARCH_GET_ADDRESSES, searchGetAddressesSaga)]);
  yield all([takeLatest(actions.PULL_RESULTS, getResultsSaga)]);
  yield all([takeLatest(actions.PULL_RATINGS, getRatingsSaga)]);
  yield all([takeLatest(actions.SIGN_PETITION, signPetitionSaga)]);
  yield all([takeLatest(actions.CONFIRM_SUBMISSION, confirmSubmissionSaga)]);
  yield all([
    takeLatest(actions.UNCONFIRM_SUBMISSION, unconfirmSubmissionSaga),
  ]);
}

export function* searchGetAddressesSaga(action) {
  const { postcode } = action.payload;
  if (postcode.length < 5) {
    yield put(actions.searchSetList({ items: [] }));
  } else {
    try {
      const response = yield call(ApiService.getAddressesByPostCode, postcode);
      yield put(actions.searchSetList({ items: response.data.data }));
    } catch (error) {
      if (error.response && error.response.data && error.response.data.data) {
        yield put(actions.searchSetError({ code: error.response.data.data.code }));
      } else {
        handleError(error);
        yield put(actions.searchSetList({ items: [] }));
      }
    }
  }
}

export function* getResultsSaga(action) {
  const { id } = action.payload;
  try {
    const response = yield call(ApiService.getAddress, id);
    if (response.data) {
      yield put(actions.fillResults({ data: response.data.data }));
    }
  } catch (error) {
    handleError(error);
    yield put(actions.pullResultsFail());
  }
}

export function* getRatingsSaga(action) {
  try {
    const response = yield call(ApiService.getRatings);
    if (response.data) {
      yield put(actions.fillRatings({ data: response.data.data }));
    }
  } catch (error) {
    handleError(error);
  }
}

export function* signPetitionSaga(action) {
  const { payload: values, meta: formikBag } = action;
  try {
    const response = yield call(
      ApiService.signPetition,
      values.name,
      values.email,
      values.addressId
    );
    if (response) {
      formikBag.setSubmitting(false);
      formikBag.setStatus({ successful: true, msg: "" });
    }
  } catch (error) {
    formikBag.setSubmitting(false);
    if (error.response.data.error) {
      formikBag.setStatus({
        successful: false,
        msg: error.response.data.error,
      });
    } else {
      handleError(error);
      formikBag.setStatus({
        successful: false,
        msg: strings.solutions.serverError,
      });
    }
  }
}

export function* confirmSubmissionSaga(action) {
  const { email, token } = action.payload;
  try {
    const response = yield call(ApiService.confirmSubmission, email, token);
    if (response.status === HTTP_STATUSES.noContent) {
      yield put(
        actions.setSubmissionConfirmationStatus({
          newStatus: strings.submissionConfirmation.confirmed,
        })
      );
    }
  } catch (error) {
    if (error.response.status === HTTP_STATUSES.badRequest) {
      yield put(
        actions.setSubmissionConfirmationStatus({
          newStatus: strings.submissionConfirmation.error,
        })
      );
    } else {
      handleError(error);
      yield put(
        actions.setSubmissionConfirmationStatus({
          newStatus: strings.submissionConfirmation.serverError,
        })
      );
    }
  }
}
export function* unconfirmSubmissionSaga(action) {
  const { email, token } = action.payload;
  try {
    const response = yield call(ApiService.unconfirmSubmission, email, token);
    if (response.status === HTTP_STATUSES.noContent) {
      yield put(
        actions.setSubmissionUnconfirmationStatus({
          newStatus: strings.submissionUnconfirmation.confirmed,
        })
      );
    }
  } catch (error) {
    if (error.response.status === HTTP_STATUSES.badRequest) {
      yield put(
        actions.setSubmissionUnconfirmationStatus({
          newStatus: strings.submissionUnconfirmation.error,
        })
      );
    } else {
      handleError(error);
      yield put(
        actions.setSubmissionUnconfirmationStatus({
          newStatus: strings.submissionUnconfirmation.serverError,
        })
      );
    }
  }
}
