import React, { Component } from 'react' //eslint-disable-line

class ScrollTopOnMount extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return <></>
  }
}

export default ScrollTopOnMount
