import { createReducer } from "redux-starter-kit";
import * as actions from "./actions";
import { addTargetBlankToLinks } from "../utils";

export const initialState = {
  postcodeSearch: {
    currentSearch: "",
    results: [],
    isAreaError: false,
    isWorking: false,
  },
  results: {
    pullResultsHasFailed: false,
    currentId: null,
    address: {},
    lat: 0,
    lng: 0,
    mpName: "",
    districtName: "",
    solutions: {},
    demandAction: "",
    streetViewUrls: {
      lg: "",
      sm: "",
    },
    concentration: null,
    healthCosts: null,
    level: null,
    levelDesc: null,
    whoLimit: null,
    whoLimitDescription: null,
  },
  ratings: [],
  submissionConfirmationStatus: "",
};

/* NOTE: createReducer from redux-starter-kit allows for direct state mutation */
export const reducer = createReducer(initialState, {
  [actions.SEARCH_SET_LIST]: (state, action) => {
    const { items } = action.payload;
    state.postcodeSearch.results = items;
    state.postcodeSearch.isAreaError = false;
    state.postcodeSearch.isWorking = false;
  },
  [actions.SEARCH_SET_ERROR]: (state, action) => {
    const { code } = action.payload;
    state.postcodeSearch.results = [];
    state.postcodeSearch.isAreaError = code === 2003;
    state.postcodeSearch.isWorking = false;
  },
  [actions.SEARCH_UPDATE_CURRENT]: (state, action) => {
    const { newValue } = action.payload;
    state.postcodeSearch.currentSearch = newValue;
  },
  [actions.SEARCH_GET_ADDRESSES]: (state, action) => {
    state.postcodeSearch.isWorking = true;
  },
  [actions.FILL_RESULTS]: (state, action) => {
    const { data } = action.payload;
    const {
      healthCosts,
      level,
      levelDesc,
      whoLimit,
      whoLimitDescription,
    } = addTargetBlankToLinks(data.airPollution.rating);
    state.results.percentile = data.airPollution.percentile;
    state.results.concentration = data.airPollution.concentration;
    state.results.healthCosts = healthCosts;
    state.results.level = level;
    state.results.levelDesc = levelDesc;
    state.results.whoLimit = whoLimit;
    state.results.whoLimitDescription = whoLimitDescription;
    state.results.currentId = data.id;
    state.results.mpName = data.postcode.mp.name;
    state.results.districtName = data.postcode.district;
    state.results.address = {
      city: data.city,
      district: data.district,
      line1: data.line1,
      line2: data.line2,
    };
    state.results.lat = data.latitude;
    state.results.lng = data.longitude;
    let solutionsByType = {};
    data.solutions.forEach(solution => {
      solutionsByType[solution.type] = solution;
    });
    state.results.solutions = solutionsByType;
    state.results.streetViewUrls.lg = data.desktopImage;
    state.results.streetViewUrls.sm = data.mobileImage;
  },
  [actions.CLEAN_RESULTS]: (state, action) => {
    state.results = initialState.results;
  },
  [actions.FILL_RATINGS]: (state, action) => {
    const { data } = action.payload;
    state.ratings = data;
  },
  [actions.PULL_RESULTS_FAIL]: (state, action) => {
    state.results.pullResultsHasFailed = true;
  },
  [actions.SET_SUBMISSION_CONFIRMATION_STATUS]: (state, action) => {
    const { newStatus } = action.payload;
    state.submissionConfirmationStatus = newStatus;
  },
  [actions.SET_SUBMISSION_UNCONFIRMATION_STATUS]: (state, action) => {
    const { newStatus } = action.payload;
    state.submissionUnconfirmationStatus = newStatus;
  },
});

export default reducer;
