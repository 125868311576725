import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import dompurify from "dompurify";

/**
 * Calculates true viewport height
 *
 * @returns {number}
 */
export const getViewportHeight = () =>
  Math.min(
    window.innerHeight || Infinity,
    window.screen.innerHeight || Infinity,
    window.height || Infinity,
    window.screen.availHeight || Infinity
  );

/**
 * Calculates true viewport width
 *
 * @returns {number}
 */
export const getViewportWidth = () =>
  Math.min(
    window.innerWidth || Infinity,
    window.screen.innerWidth || Infinity,
    window.width || Infinity,
    window.screen.availWidth || Infinity
  );

/**
 * Checks whether current device is mobile
 *
 * @returns {boolean}
 */
export const isMobile = () => {
  var check = false;
  /* eslint-disable */
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
  /* eslint-enable */
};

/**
 * Scroll window to element
 * @param {Element} el
 */
export const scrollWindowToElement = el => {
  var elRect = el.getBoundingClientRect();
  setTimeout(() => {
    window.scroll(0, elRect.y);
  }, 200);
};

/**
 * Scroll parent elements scrollbar to show child element
 * @param {Element} parent
 * @param {Element} child
 */
export const scrollParentToChild = (parent, child) => {
  var parentRect = parent.getBoundingClientRect();
  var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };
  var childRect = child.getBoundingClientRect();
  var isViewable =
    childRect.top + 10 >= parentRect.top &&
    childRect.top + 10 <= parentRect.top + parentViewableArea.height;
  if (!isViewable) {
    parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
  }
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter your name")
    .required("Please enter your name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter valid email"),
});

export const handleError = error => {
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_SENTRY_URL
  ) {
    Sentry.captureException(error);
  } else {
    console.log(error);
  }
};

export const HTTP_STATUSES = {
  noContent: 204,
  badRequest: 400,
};

export const KEYSTROKES = {
  enter: "Enter",
  arrowDown: "ArrowDown",
  arrowUp: "ArrowUp",
};

export const TYPES = {
  object: "object",
  string: "string",
};

/**
 * Iterate over object recursively and add target="_blank" to all <a> tags inside all strings.
 * @param {Object} obj
 *
 * @returns {Object}
 */
export const addTargetBlankToLinks = obj => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === TYPES.object) {
      addTargetBlankToLinks(obj[key]);
    }
    if (typeof obj[key] === TYPES.string) {
      obj[key] = obj[key].replace(/<a/gm, '<a target="_blank"');
    }
  });
  return obj;
};

/**
 * Sanitize string with default configuration
 * @param {string} string
 *
 * @returns {string}
 */
export const sanitizer = string => {
  return dompurify.sanitize(string, { ADD_ATTR: ["target"] });
};

/**
 * Check if string is a valid postcode
 * @param {string} string
 *
 * @returns {bool}
 */
export const isValidPostcode = string => {
  const postCodeRegex = /^([G][I][R]0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-H-J-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-H-J-Y][0-9]?[A-Z]))))[0-9][A-Z]{2})$/m;
  const stringWithoutWhitespace = string.replace(/\s/g, "")
  const postcode = stringWithoutWhitespace.toUpperCase();
  const isValid = !!postcode.match(postCodeRegex);
  return isValid;
}