import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./routes/App/App";
import Store from "./store";
import "./styles/_core.scss";
import { unregister } from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";

unregister(); // Worst part of CRA

if (process.env.REACT_APP_ANALYTICS_UA) {
  ReactGA.initialize({
    trackingId: process.env.REACT_APP_ANALYTICS_UA,
    gaOptions: {
      anonymizeIp: true,
    },
  });
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
  });
}

const store = Store.configure();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
