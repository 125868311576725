import React, { Component } from "react";
import pt from "prop-types";
import { Link } from "react-router-dom";
import strings from "../../strings/";
import LandingImage from "../../images/landing-image.gif";
import ROUTES from "../index";
import ScrollToTopOnMount from "../../components/Common/ScrollTopOnMount/ScrollTopOnMount";

class Unsubscribe extends Component {
  render() {
    const { email, token } = this.props.match.params;
    const linkTarget = `/${ROUTES.unconfirmSubmission}/${email}/${token}`;
    return (
      <div className="Unsubscribe">
        <ScrollToTopOnMount></ScrollToTopOnMount>
        <div className="Unsubscribe__image">
          <img alt="" src={LandingImage} />
        </div>
        <Link to={linkTarget}>{strings.unsubscribe.link}</Link>
      </div>
    );
  }
}

Unsubscribe.propTypes = {
  match: pt.object,
};

export default Unsubscribe;
