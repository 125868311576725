import React, { Component } from "react";
import strings from "../../../strings";
import { Link } from "react-router-dom";
import { sanitizer } from "../../../utils/";
import pt from "prop-types";
import Attribution from "../../../components/Results/Attribution/Attribution";
import SocialIcons from "../SocialIcons/SocialIcons";

class AppFooter extends Component {
  render() {
    return (
      <div className="AppFooter">
        {this.props.isResults && <Attribution />}
        <div className="AppFooter__message">
          {this.props.isHome && <div> {strings.footer.version}</div>}
          {!this.props.isHome && <SocialIcons />}
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(strings.footer.message1),
            }}
          ></div>
          <div className="AppFooter__link">
            <Link to="/page/privacyPolicy">
              {strings.footer.privacyPolicyLink}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

AppFooter.propTypes = {
  isHome: pt.bool,
};

export default AppFooter;
