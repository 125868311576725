import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import pt from 'prop-types'
import { connect } from 'react-redux'
import * as selectors from '../../selectors'
import * as actions from '../../modules/actions'
import StreetView from '../../components/Results/StreetView/StreetView'
import PollutionDescription from '../../components/Results/PollutionDescription/PollutionDescription'
import Address from '../../components/Results/Address/Address'
import DropdownText from '../../components/Common/DropdownText/DropdownText'
import strings from '../../strings'
import { Link, Redirect } from 'react-router-dom'
import Button from '../../components/Common/Button/Button'
import ResultsIcon from '../../images/results-icon.svg'
import Spinner from '../../components/Common/Spinner/Spinner'
import ROUTES from '../index'
import classNames from 'classnames'
import { getViewportWidth } from '../../utils/'
import ScrollToTopOnMount from '../../components/Common/ScrollTopOnMount/ScrollTopOnMount'

import { detect } from 'detect-browser';

class Results extends Component {
  constructor (props) {
    super(props)
    this.cta = React.createRef()
    this.description = React.createRef()
    this.attribution = React.createRef()
    this.result = React.createRef()
  }

  componentDidMount () {
    if (this.props.match.params.id !== this.props.currentId) {
      this.props.cleanResults()
      this.props.pullResults({ id: this.props.match.params.id })
    }
    window.addEventListener('resize', this.setCtaPosition.bind(this))
  }
  componentDidUpdate () {
    this.setCtaPosition()
  }
  setCtaPosition () {
    if (this.cta.current && this.description.current) {
      const cta = ReactDOM.findDOMNode(this.cta.current)
      const description = ReactDOM.findDOMNode(this.description.current)
      if (getViewportWidth() > 799) {
        cta.style.top = description.offsetTop + 258 + 'px'
      } else {
        cta.style.top = 'auto'
      }
      this.setDisclaimerPosition();
    }
  }
  setDisclaimerPosition () {
    if (
      this.cta.current &&
      this.attribution.current &&
      this.description.current
    ) {
      const browser = detect();
      // get attribution div
      const attribution = ReactDOM.findDOMNode(this.attribution.current);
      // get CTA
      const cta = ReactDOM.findDOMNode(this.cta.current);
      const ctaRect = cta.getBoundingClientRect();
      // calculate bottom
      let bottom = window.innerHeight - (ctaRect.bottom + 105);
      // adjustment for safari
      if(browser.name === 'safari') {
        bottom += 3;
      }
      attribution.style.opacity = 1;
      if (getViewportWidth() > 799) {
        attribution.style.bottom = bottom + 'px'
      } else {
        attribution.style.top = 'auto'
      }
    }
  }
  render () {
    const { address, lat, lng, streetViewUrls, percentile, whoLimit, whoLimitDescription, level, healthCosts, levelDesc, pullResultsHasFailed } = this.props
    const solutionsPath = `/${ROUTES.solutions}/` + this.props.match.params.id
    const levelClass = level
      ? 'Results--' + level
      : 'Results--loading';
    const classes = classNames('Results', levelClass);
    return (
      <React.Fragment>
        <div className={classes} ref={this.result}>
          <ScrollToTopOnMount></ScrollToTopOnMount>
          {!level && (
            <div className='Results__content'>
              <Spinner />
            </div>
          )}
          {level && (
            <div className='Results__content'>
              <div className='Results__headline'>
                <p>{strings.results.headline.split(" ").map((word, i) => (
                  <span key={i} className="linebreak-span">{word}{" "}</span>
                ))}</p>
                <img className="Results__headline__logo" src={ResultsIcon} alt='' />
              </div>

              <Address address={address} lat={lat} lng={lng} />
              <StreetView
                ovedrrideUrl={streetViewUrls.lg}
                lat={lat}
                lng={lng}
                backgroundMode={true}
              />
              <StreetView
                ovedrrideUrl={streetViewUrls.sm}
                lat={lat}
                lng={lng}
              />
              <PollutionDescription
                ref={this.description}
                percentile={percentile}
                whoLimit={whoLimit}
                whoLimitDescription={whoLimitDescription}
                levelName={levelDesc}
                levelNumber={level}
              />
              <DropdownText
                title={strings.results.healthCostsDropdown}
                titleContainsHtml={true}
                containsHtml={true}
              >
                {healthCosts}
              </DropdownText>
            </div>
          )}
          <Link
            ref={this.cta}
            className={classNames('Results__cta', {
              'Results__cta--isMinimum': !!(level === 1)
            })}
            to={solutionsPath}
          >
            <Button
              isLarge={true}
              isWhite={true}
              label={strings.results.solutionsCta}
            />
          </Link>
        </div>
        {pullResultsHasFailed && <Redirect to="/" />}
      </React.Fragment>
    )
  }
}

Results.propTypes = {
  match: pt.object,
  address: pt.object,
  airPollution: pt.object,
  lat: pt.number,
  lng: pt.number,
  currentId: pt.string,
  pullResultsHasFailed: pt.bool,
}

const mapStateToProps = state => {
  return {
    address: selectors.resultsAddress(state),
    lat: selectors.resultsLat(state),
    lng: selectors.resultsLng(state),
    currentId: selectors.resultsCurrentId(state),
    streetViewUrls: selectors.resultsStreetViewUrls(state),
    concentration: selectors.resultsConcentration(state),
    healthCosts: selectors.resultsHealthCosts(state),
    level: selectors.resultsLevel(state),
    levelDesc: selectors.resultsLevelDesc(state),
    percentile: selectors.resultsPercentile(state),
    whoLimit: selectors.resultsWhoLimit(state),
    whoLimitDescription: selectors.resultsWhoLimitDescription(state),
    pullResultsHasFailed: selectors.resultsPullResultsHasFailed(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    pullResults: payload => dispatch(actions.pullResults(payload)),
    cleanResults: payload => dispatch(actions.cleanResults(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)
